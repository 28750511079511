import { useTranslation } from "react-i18next";
import { Watermark } from "../../template";
import PngExportable from "../PngExportable";
import Table from "../Table";
import Day from "../Day";
import Pie from "../../charts/Pie";
import Unit from "../Unit";
import Location from "../Location";
import Hour from "../Hour";
import TableExportable from "../TableExportable";
import CreatedBy from "../CreatedBy";

const IncidentReport = ({
	start,
	end,
	day,
	category,
	unit,
	location,
	probability,
	damage,
	assessment,
	hour,
	table,
	createdby,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<PngExportable title={t(`report_title.incident_day`, { start, end })}>
				<div style={{ height: 500 }} className="relative">
					<Watermark />
					<Day
						data={day.map(({ _id, pending, inprogress, completed, total }) => ({
							_id,
							[t(`const.incident_status.pending`)]: pending,
							[t(`const.incident_status.inprogress`)]: inprogress,
							[t(`const.incident_status.completed`)]: completed,
							[t(`total`)]: total,
						}))}
						statuses={[
							{ status: "pending", color: "#D90429" },
							{ status: "inprogress", color: "#FCA311" },
							{ status: "completed", color: "#008000" },
						]}
						tPath="const.incident_status"
					></Day>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.incident_category`, { start, end })}
			>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center relative">
					<Watermark />
					<Pie
						height={450}
						data={category}
						dataKey="total"
						renderLabel={({ name, total }) => {
							return `${name} (${total})`;
						}}
					/>
					<Table
						data={category}
						tPath="const.incident_status"
						columns={["pending", "inprogress", "completed", "total"]}
					/>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.incident_unit`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={unit}
						tPath="const.incident_status"
						columns={["pending", "inprogress", "completed", "total"]}
					/>
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={unit.map(({ pending, inprogress, completed, name }) => {
							return {
								name,
								children: [
									{
										name: t(`const.incident_status.pending`),
										total: pending,
										color: "#D90429",
									},
									{
										name: t(`const.incident_status.inprogress`),
										total: inprogress,
										color: "#FCA311",
									},
									{
										name: t(`const.incident_status.completed`),
										total: completed,
										color: "#008000",
									},
								],
							};
						})}
					></Unit>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.incident_createdby`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy data={createdby}></CreatedBy>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.incident_hour`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Hour
						data={hour}
						renderColor={(value) => {
							if (value < 6) return "bg-def-green text-white";
							if (value > 5 && value < 11) return "bg-def-secondary text-white";
							if (value > 10) return "bg-def-red text-white";
						}}
					/>
				</div>
			</PngExportable>
			<div className="grid grid-cols-1 lg:grid-cols-2">
				<PngExportable
					title={t(`report_title.incident_probability`, { start, end })}
				>
					<div className="relative">
						<Watermark />
						<Pie
							height={450}
							data={probability.map(({ name, total }) => ({
								value: total,
								name: t(`const.probability.${name}`),
							}))}
							renderLabel={({ name, value }) => {
								return `${name} (${value})`;
							}}
						/>
					</div>
					<div className="relative">
						<Watermark />
						<Table
							data={probability}
							renderRowName={({ name }) => t(`const.probability.${name}`)}
							tPath="const.incident_status"
							columns={["pending", "inprogress", "completed", "total"]}
						/>
					</div>
				</PngExportable>
				<PngExportable
					title={t(`report_title.incident_damage`, { start, end })}
				>
					<div className="relative">
						<Watermark />
						<Pie
							height={450}
							data={damage.map(({ name, total }) => ({
								value: total,
								name: t(`const.damage.${name}`),
							}))}
							renderLabel={({ name, value }) => {
								return `${name} (${value})`;
							}}
						/>
					</div>
					<div className="relative">
						<Watermark />
						<Table
							data={damage}
							renderRowName={({ name }) => t(`const.damage.${name}`)}
							tPath="const.incident_status"
							columns={["pending", "inprogress", "completed", "total"]}
						/>
					</div>
				</PngExportable>
			</div>
			<PngExportable
				title={t(`report_title.incident_assessment`, { start, end })}
			>
				<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 items-center relative">
					<Watermark />
					<Pie
						height={450}
						data={assessment.map(({ name, total }) => ({
							value: total,
							name: t(`const.assessment.${name}`),
						}))}
						renderLabel={({ name, value }) => {
							return `${name} (${value})`;
						}}
					/>
					<Table
						data={assessment}
						renderRowName={({ name }) => t(`const.assessment.${name}`)}
						tPath="const.incident_status"
						columns={["pending", "inprogress", "completed", "total"]}
					/>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.incident_location`, { start, end })}
			>
				<div className="relative">
					<Watermark />
					<Table
						data={location}
						tPath="const.incident_status"
						columns={["pending", "inprogress", "completed", "total"]}
					/>
				</div>
				<div className="mt-4">
					<Location data={location} />
				</div>
			</PngExportable>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.incident_table`, { start, end })}
					find={{ start, end }}
					url="/report/incident/export"
					data={table}
					columns={[
						{
							label: t("fields.number"),
							render: { type: "string", field: "number" },
						},
						{
							label: t("fields.location"),
							render: { type: "string", field: "location.name" },
						},
						{
							label: t("fields.unit"),
							render: { type: "string", field: "unit.name" },
						},
						{
							label: t("fields.createdby"),
							render: ({ createdby }) =>
								`${createdby.firstname} ${createdby.lastname} (${createdby.position})`,
						},
						{
							label: t("incident.probability"),
							render: {
								type: "trans",
								tPath: "const.probability",
								field: "probability",
							},
						},
						{
							label: t("incident.damage"),
							render: {
								type: "trans",
								tPath: "const.damage",
								field: "damage",
							},
						},
						{
							label: t("incident.assessment_name"),
							render: {
								type: "trans",
								tPath: "const.assessment",
								field: "assessment_name",
							},
						},
						{
							label: t("incident.status"),
							render: {
								type: "trans",
								tPath: "const.incident_status",
								field: "status",
							},
						},
						{
							label: t("incident.info"),
							render: { type: "string", field: "info" },
						},
						{
							label: t("fields.created"),
							render: { type: "date", field: "created" },
						},
						{
							label: t("incident.workers"),
							render: ({ workers }) =>
								Array.isArray(workers) &&
								workers
									.map(
										(worker) =>
											`${worker.firstname} ${worker.lastname} (${worker.position})`
									)
									.join(", "),
						},
					]}
				/>
			</div>
		</div>
	);
};

export default IncidentReport;
