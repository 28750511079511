import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import {
	RiBroadcastLine,
	RiCameraLine,
	RiCheckboxLine,
	RiDeviceLine,
	RiEditBoxLine,
	RiFileEditLine,
	RiFolder2Line,
	RiLineChartLine,
	RiListOrdered,
	RiNewspaperLine,
	RiPhoneLine,
	RiShieldCheckLine,
	RiUserStarLine,
	RiVideoLine,
	RiMenuLine,
	RiCloseLine,
} from "react-icons/ri";
import { ENV, LOGO } from "../defines";
import DemoForm from "../demo/Form";
import LanguageSwitch from "../i18n/Switch";
import { Trans, useTranslation } from "react-i18next";
import Swal from "sweetalert2";

const showModal = (src, alt) => {
	return Swal.fire({
		imageUrl: src,
		imageAlt: alt,
		heightAuto: true,
		width: 800,
		showConfirmButton: false,
		margin: 0,
		padding: 5,
	});
};
const PARTNERS = {
	gafety: [],
	bukhbat: [
		{
			src: "/images/home/bukhbat/partners/premium4.png",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/mobicom.png",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/achit_ikht.png",
		},
		{
			src: "/images/home/bukhbat/partners/premium.png",
		},
		{
			src: "/images/home/bukhbat/partners/next.png",
		},
		{
			src: "/images/home/bukhbat/partners/premium9.png",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/hunnu.png",
		},
		{
			src: "/images/home/bukhbat/partners/zt.jpg",
			height: 100,
		},
		{
			src: "/images/home/bukhbat/partners/zt4.jpg",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/1.jpg",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/2.jpg",
			height: 60,
		},
		{
			src: "/images/home/bukhbat/partners/3.jpg",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/5.jpg",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/6.jpg",
		},
		{
			src: "/images/home/bukhbat/partners/7.jpg",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/8.png",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/9.png",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/khovd_dtss.png",
		},
		{
			src: "/images/home/bukhbat/partners/10.png",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/premium1.jpg",
			height: 80,
		},
		{
			src: "/images/home/bukhbat/partners/premium2.png",
			height: 60,
		},
		{
			src: "/images/home/bukhbat/partners/premium3.png",
			height: 50,
		},
		{
			src: "/images/home/bukhbat/partners/premium5.png",
			height: 50,
		},
		{
			src: "/images/home/bukhbat/partners/premium6.png",
			height: 40,
		},
		{
			src: "/images/home/bukhbat/partners/premium7.png",
			height: 50,
		},
		{
			src: "/images/home/bukhbat/partners/premium8.png",
			height: 60,
		},
		{
			src: "/images/home/bukhbat/partners/khan-urnud-mining.jpeg",
			height: 120,
		},
		{
			src: "/images/home/bukhbat/partners/namnan.png",
			height: 120,
		},
	],
};

const MENU = [
	"home",
	"features",
	PARTNERS[ENV].length > 0 ? "partners" : null,
	"about",
	"demo",
].filter((i) => i);

const FEATURES = [
	{
		icon: <RiFileEditLine />,
		label: "1",
	},
	{
		icon: <RiCameraLine />,
		label: "2",
	},
	{
		icon: <RiDeviceLine />,
		label: "3",
	},
	{
		icon: <RiVideoLine />,
		label: "4",
	},
	{
		icon: <RiEditBoxLine />,
		label: "5",
	},
	{
		icon: <RiShieldCheckLine />,
		label: "6",
	},
	{
		icon: <RiPhoneLine />,
		label: "7",
	},
	{
		icon: <RiUserStarLine />,
		label: "8",
	},
	{
		icon: <RiNewspaperLine />,
		label: "9",
	},
	{
		icon: <RiListOrdered />,
		label: "10",
	},
	{
		icon: <RiCheckboxLine />,
		label: "11",
	},
	{
		icon: <RiBroadcastLine />,
		label: "12",
	},
	{
		icon: <RiLineChartLine />,
		label: "13",
	},
	{
		icon: <RiFolder2Line />,
		label: "14",
	},
];

const MENU_HEIGHT = 60;
const Home = () => {
	const [selected, setSelected] = useState("home");
	const [open, setOpen] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		const element = document.getElementById(selected);
		if (element) {
			const yOffset = MENU_HEIGHT * -1;
			const y =
				element.getBoundingClientRect().top + window.pageYOffset + yOffset;

			window.scrollTo({ top: y, behavior: "smooth" });
		}
	}, [selected]);

	return (
		<div className="relative">
			{open && (
				<div
					className="flex fixed bg-black bg-opacity-30 z-20 top-0 left-0 right-0 bottom-0 h-full w-full"
					onClick={() => {
						setOpen(false);
					}}
				>
					<div className="bg-white" style={{ width: 250 }}>
						<div className="m-2 my-4 flex justify-between">
							<img src={LOGO} style={{ height: 30 }} alt="Logo" />
							<div
								className="flex md:hidden border items-center justify-center pointer"
								style={{ height: 30, width: 30 }}
							>
								<RiCloseLine />
							</div>
						</div>
						<div className="border-t">
							{MENU.map((item) => (
								<div
									key={item}
									className={`cursor-pointer p-2 ${
										selected === item
											? "border-b-2 border-def-secondary"
											: "border-b"
									}`}
									onClick={() => {
										setSelected(item);
									}}
								>
									{t(`landing.menu.${item}`)}
								</div>
							))}
						</div>
					</div>
				</div>
			)}
			<div
				className="bg-white sticky top-0 z-10 border-b-2 px-2"
				style={{ height: MENU_HEIGHT }}
			>
				<div
					className="container mx-auto flex justify-between items-center"
					style={{ height: MENU_HEIGHT }}
				>
					<div
						className="flex md:hidden border items-center justify-center pointer"
						style={{ height: 30, width: 30 }}
						onClick={() => {
							setOpen(true);
						}}
					>
						<RiMenuLine />
					</div>
					<img
						src={LOGO}
						style={{ height: 30 }}
						alt="Logo"
						className="hidden md:flex"
					/>
					<div className="hidden md:flex gap-4">
						{MENU.map((item) => (
							<div
								key={item}
								className={`cursor-pointer ${
									selected === item ? "border-b-2 border-def-secondary" : ""
								}`}
								onClick={() => {
									setSelected(item);
								}}
							>
								{t(`landing.menu.${item}`)}
							</div>
						))}
					</div>
					<div className="flex items-center">
						<div className="mr-8">
							<LanguageSwitch></LanguageSwitch>
						</div>
						<Link to="/auth/login" className="button secondary">
							{t("button.login")}
						</Link>
					</div>
				</div>
			</div>
			<div className="relative bg-white px-2" id="home">
				<img
					alt="Safety software"
					src="images/home/banner.png"
					style={{ height: 600 }}
					className="w-full object-cover opacity-20"
				/>
				<div className="absolute top-0 left-0 right-0 bottom-0 flex items-center px-2">
					<div className="container mx-auto block lg:flex gap-2 mt-8 lg:mt-0">
						<div className="flex-1">
							<img
								src={`/images/home/${ENV}/mockup2.png`}
								alt="Safety software"
							/>
						</div>
						<div className="flex-1 text-xl xl:text-3xl self-center">
							<span className="mr-1 font-bold capitalize">{ENV}</span>{" "}
							{t("landing.label.is")}
							<span className="flex">
								<Typewriter
									options={{
										strings: [
											t("landing.label.writers.0"),
											t("landing.label.writers.1"),
											t("landing.label.writers.2"),
										],
										autoStart: true,
										loop: true,
									}}
								/>
							</span>
							{t("landing.label.thing")}
						</div>
					</div>
				</div>
			</div>
			<div className="container mx-auto bg-white p-5" id="features">
				<h1 className="text-2xl text-center mt-5 mb-8 pb-8 uppercase border-b-2 border-def-secondary">
					{t(`landing.title.features`)}
				</h1>
				<div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-5">
					{FEATURES.map(({ label }, index) => {
						const text = t(`landing.features.${label}`);
						return (
							<div
								key={index}
								className="text-center shadow-md p-5 cursor-pointer hover:bg-def-secondary hover:text-white"
								onClick={() => {
									showModal(`/images/home/${ENV}/${index + 1}.png`, text);
								}}
							>
								<div className="flex justify-center text-def-primary">
									<img
										src={`/images/home/${ENV}/${index + 1}_thumb.png`}
										alt={text}
									/>
								</div>
								<div className="text-sm p-5">{text}</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="bg-def-primary">
				<img src={`images/home/${ENV}/mobile_1.png`} alt="Safety software" />
			</div>
			<div className="container mx-auto bg-white p-5" id="about">
				<h1 className="text-2xl text-center mt-5 mb-8 pb-8 uppercase border-b-2 border-def-secondary">
					{t(`landing.title.about`)}
				</h1>
				<div className="block md:flex gap-2 items-center">
					<div className="flex-1 my-8 text-right">
						<div className="text-2xl">
							<Trans t={t} values={{ system: ENV.toUpperCase() }}>
								landing.label.iso
							</Trans>
						</div>
						<div className="mt-4">
							<Trans t={t} values={{ system: ENV.toUpperCase() }}>
								landing.label.invent
							</Trans>
						</div>
					</div>
					<div className="flex-1">
						<img
							src={`/images/home/${ENV}/mockup1.png`}
							alt="Safety software"
						/>
					</div>
				</div>
			</div>
			{PARTNERS[ENV].length > 0 && (
				<div className="container mx-auto bg-white p-5" id="partners">
					<h1 className="text-2xl text-center mt-5 mb-8 pb-8 uppercase border-b-2 border-def-secondary">
						{t(`landing.title.partners`)}
					</h1>
					<div className="flex flex-wrap items-center justify-center gap-12 pt-5">
						{PARTNERS[ENV].map(({ src, height }) => (
							<img
								key={src}
								alt={ENV + " partner"}
								className="object-contain transition-all cursor-pointer max-w-xs"
								src={src}
								style={{ height: height || 50 }}
							></img>
						))}
					</div>
				</div>
			)}
			<div className="container mx-auto bg-white p-5" id="demo">
				<h1 className="text-2xl text-center mt-5 mb-8 pb-8 uppercase border-b-2 border-def-secondary">
					{t(`landing.title.demo`)}
				</h1>
				<div className="block md:flex gap-2 items-center">
					<div style={{ maxWidth: 1000 }} className="mx-auto my-8 mb-16">
						<DemoForm />
					</div>
				</div>
			</div>
			<div className="text-right py-16" style={{ background: "#f9f9f9" }}>
				<div className="container mx-auto px-4 flex justify-between items-center">
					<div className="hidden sm:block">
						<img
							src={LOGO}
							style={{ height: 180 }}
							alt="Logo"
							className="opacity-10"
						/>
					</div>
					<div>
						<h1 className="uppercase font-medium">
							{t("landing.title.contact")}
						</h1>
						<div className="text-sm">
							<div className="mb-8">{t("landing.footer.address")}</div>
							<div className="mb-4">
								<span className="">{t("landing.label.phone")}:</span>{" "}
								<a className="font-bold underline" href="tel:(+976) 75054411">
									(+976) 75054411
								</a>
							</div>
							<div className="mb-4">
								<span className="">{t("landing.label.cellphone")}:</span>{" "}
								<a className="font-bold underline" href="tel:(+976) 99102004">
									(+976) 99102004
								</a>
							</div>
							<div className="">
								<span className="">{t("landing.label.email")}:</span>{" "}
								<a
									className="font-bold underline"
									href="mailto:info@gafety.com"
								>
									info@gafety.com
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<footer
				style={{ height: 120 }}
				className="bg-def-primary flex items-center text-sm"
			>
				<div className="container mx-auto text-white text-right px-4 flex justify-between items-center">
					<div>©2024 {t("landing.footer.company")}</div>
					<div className="flex flex-col">
						<Link className="underline mb-2" to={"/terms"}>
							{t("button.terms")}
						</Link>
						<Link className="underline" to={"/privacy"}>
							{t("button.privacy")}
						</Link>
					</div>
				</div>
			</footer>
		</div>
	);
};

export default Home;
