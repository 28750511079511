import { useTranslation } from "react-i18next";
import { RiCalendar2Line } from "react-icons/ri";

const Chain = ({ data }) => {
	const { t } = useTranslation();
	const max = data[data.length - 1]?._id;
	const items = new Array(max).fill(0);

	data.map(({ _id, total }) => (items[_id] = total));

	return (
		<div className="flex flex-wrap">
			<div className="p-5 bg-def-green self-center text-white items-center">
				<RiCalendar2Line className="text-2xl" />
			</div>
			{items.map((item, index) => (
				<div className="flex items-center my-4" key={index}>
					<div
						className={`h-1 w-12 ${
							index > 5
								? index > 10
									? "bg-def-red"
									: "bg-def-secondary"
								: "bg-def-green"
						}`}
					></div>
					<div
						className={`flex justify-center items-center text-white rounded-full ${
							index > 5
								? index > 10
									? "bg-def-red"
									: "bg-def-secondary"
								: "bg-def-green"
						} relative ${item > 0 ? "w-12 h-12" : "w-6 h-6"}`}
					>
						{item > 0 ? item : ""}
						<div className="absolute text-xs top-0 text-def-primary -mt-4 w-32 text-center">
							{t("report_label.day")} {index}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default Chain;
