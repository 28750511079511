import { useTranslation } from "react-i18next";
import { Watermark } from "../../template";
import PngExportable from "../PngExportable";
import TableExportable from "../TableExportable";
import Table from "../Table";
import Unit from "../Unit";
import Pie from "../../charts/Pie";
import PlanProgress from "../PlanProgress";
import Dow from "../Dow";
import CreatedBy from "../CreatedBy";
import Chain from "../Chain";
import Day from "../Day";
import { formatDistance } from "date-fns";

const STAT_TRANS = {
	plans: "report_label.induction_plan",
	total: "report_label.induction_workers",
	signed: "const.sign_status.signed",
	notsigned: "const.sign_status.notsigned",
	percent: "report_label.induction_stat",
};

const InductionReport = ({
	day,
	start,
	end,
	stats,
	plans,
	type,
	unit,
	dow,
	delay,
	top,
	worst,
	induction_table,
	plan_table,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<PngExportable title={t(`report_title.induction_stat`, { start, end })}>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-5 relative">
					<Watermark />
					<div className="grid gap-5">
						{stats.map(({ name, value }) => (
							<div
								key={name}
								className="p-5 bg-white shadow-md flex justify-between"
							>
								<div>{t(STAT_TRANS[name])}</div>
								<div className="text-2xl font-bold">{value}</div>
							</div>
						))}
					</div>
					<Pie
						height={450}
						data={type.map(({ name, total }) => ({
							value: total,
							name: t(`const.induction_type.${name}`),
						}))}
						renderLabel={({ name, value }) => {
							return `${name} (${value})`;
						}}
					/>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.induction_progress`, { start, end })}
			>
				<div className="relative">
					<PlanProgress
						data={plans}
						tPath="const.sign_status"
						completedTKey="signed"
						notcompletedTKey="notsigned"
					/>
					<Watermark />
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.induction_days`, { start, end })}>
				<div style={{ height: 500 }} className="relative">
					<Watermark />
					<Day
						data={day.map(({ _id, notsigned, signed, total }) => ({
							_id,
							[t(`const.sign_status.notsigned`)]: notsigned,
							[t(`const.sign_status.signed`)]: signed,
							[t(`total`)]: total,
						}))}
						statuses={[
							{ status: "notsigned", color: "#D90429" },
							{ status: "signed", color: "#008000" },
						]}
						tPath="const.sign_status"
					></Day>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.induction_day`, { start, end })}>
				<div className="relative">
					<Dow
						data={dow}
						tPath="const.sign_status"
						renderCell={({ plans }) =>
							plans.map(({ date, name, type, total }, index) => (
								<div
									key={index}
									className={`text-xs mb-2 border shadow-md ${
										type === "exam" ? "bg-def-secondary" : ""
									}`}
								>
									<div>{date}</div>
									<div>{name}</div>
									<div>
										{t(`const.induction_type.${type}`)} ({total}{" "}
										{t("report_label.person")})
									</div>
								</div>
							))
						}
					/>
					<Watermark />
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.induction_unit`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={unit}
						tPath="const.sign_status"
						columns={["notsigned", "signed", "total"]}
					/>
				</div>
				<div className="mt-4" style={{ height: 600 }}>
					<Unit
						data={unit.map(({ notsigned, signed, name }) => {
							return {
								name,
								children: [
									{
										name: t(`const.sign_status.signed`),
										total: signed,
										color: "#008000",
									},
									{
										name: t(`const.sign_status.notsigned`),
										total: notsigned,
										color: "#D90429",
									},
								],
							};
						})}
					></Unit>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.induction_delay`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<Chain data={delay} />
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.induction_top`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy
						data={top}
						renderRight={({ signed, total }) => `${signed}/${total}`}
					></CreatedBy>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.induction_worst`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy
						data={worst}
						renderRight={({ signed, total }) => `${signed}/${total}`}
					></CreatedBy>
				</div>
			</PngExportable>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.induction_plan_table`, { start, end })}
					find={{ start, end }}
					url="/report/induction/export-plan"
					data={plan_table}
					columns={[
						{
							label: t("fields.created"),
							render: { type: "date", field: "created" },
						},
						{
							label: t("induction-plan.schedule"),
							render: { type: "date", field: "schedule" },
						},
						{
							label: t("induction-template.type"),
							render: {
								type: "trans",
								tPath: "const.induction_type",
								field: "type",
							},
						},
						{
							label: t("induction-template.name"),
							render: { type: "string", field: "template.name" },
						},
						{
							label: t("induction-plan.user_count"),
							render: { type: "string", field: "user_count" },
						},
						{
							label: t("induction-plan.signature_count"),
							render: { type: "string", field: "signature_count" },
						},
						{
							label: t("induction-plan.progress"),
							render: ({ user_count, signature_count }) =>
								((signature_count / user_count) * 100).toFixed(2),
						},
						{
							label: t("fields.createdby"),
							render: ({ createdby }) =>
								`${createdby.firstname} ${createdby.lastname} (${createdby.position})`,
						},
						{
							label: t("fields.unit"),
							render: {
								type: "string",
								field: "createdby.unit.name",
							},
						},
					]}
				/>
			</div>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.induction_table`, { start, end })}
					find={{ start, end }}
					url="/report/induction/export"
					data={induction_table}
					columns={[
						{
							label: t("fields.date"),
							render: { type: "date", field: "created" },
						},
						{
							label: t("induction-template.type"),
							render: {
								type: "trans",
								tPath: "const.induction_type",
								field: "type",
							},
						},
						{
							label: t("induction-template.name"),
							render: { type: "string", field: "induction.name" },
						},
						{
							label: t("fields.employee"),
							render: ({ employee }) =>
								`${employee.firstname} ${employee.lastname} (${employee.position})`,
						},
						{
							label: t("fields.unit"),
							render: {
								type: "string",
								field: "unit.name",
							},
						},
						{
							label: t("fields.status"),
							render: {
								type: "trans",
								tPath: "const.sign_status",
								field: "status",
							},
						},
						{
							label: t("induction-plan.signed"),
							render: { type: "date", field: "signed" },
						},
						{
							label: t("induction-plan.delay"),
							render: ({ created, signed }) =>
								signed &&
								formatDistance(new Date(signed), new Date(created), {
									includeSeconds: true,
								}),
						},
					]}
				/>
			</div>
		</div>
	);
};

export default InductionReport;
