import { useTranslation } from "react-i18next";
import { Watermark } from "../../template";
import PngExportable from "../PngExportable";
import Table from "../Table";
import Day from "../Day";
import TableExportable from "../TableExportable";
import CreatedBy from "../CreatedBy";
import { numberWithCommas } from "../../defines";

const PpeReport = ({
	start,
	end,
	day,
	day_amount,
	unit,
	unit_amount,
	item,
	item_amount,
	table,
	received,
	received_amount,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			<PngExportable title={t(`report_title.ppe_day`, { start, end })}>
				<div style={{ height: 500 }} className="relative">
					<Watermark />
					<Day
						data={day.map(({ _id, received, notreceived, total }) => ({
							_id,
							[t(`const.ppe_status.notreceived`)]: notreceived,
							[t(`const.ppe_status.received`)]: received,
							[t(`total`)]: total,
						}))}
						statuses={[
							{ status: "notreceived", color: "#FCA311" },
							{ status: "received", color: "#008000" },
						]}
						tPath="const.ppe_status"
					></Day>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.ppe_day_amount`, { start, end })}>
				<div style={{ height: 500 }} className="relative">
					<Watermark />
					<Day
						data={day_amount.map(({ _id, received, notreceived, total }) => ({
							_id,
							[t(`const.ppe_status.notreceived`)]: notreceived,
							[t(`const.ppe_status.received`)]: received,
							[t(`total`)]: total,
						}))}
						statuses={[
							{ status: "notreceived", color: "#FCA311" },
							{ status: "received", color: "#008000" },
						]}
						tPath="const.ppe_status"
					></Day>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.ppe_item`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={item}
						tPath="const.ppe_status"
						columns={["notreceived", "received", "total"]}
					/>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.ppe_item_amount`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={item_amount}
						tPath="const.ppe_status"
						columns={["notreceived", "received", "total"]}
					/>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.ppe_unit`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={unit}
						tPath="const.ppe_status"
						columns={["notreceived", "received", "total"]}
					/>
				</div>
			</PngExportable>
			<PngExportable title={t(`report_title.ppe_unit_amount`, { start, end })}>
				<div className="relative">
					<Watermark />
					<Table
						data={unit_amount}
						tPath="const.ppe_status"
						columns={["notreceived", "received", "total"]}
					/>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.ppe_received`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy data={received}></CreatedBy>
				</div>
			</PngExportable>
			<PngExportable
				title={t(`report_title.ppe_received_amount`, { start, end, top: 10 })}
			>
				<div className="relative">
					<Watermark />
					<CreatedBy data={received_amount}></CreatedBy>
				</div>
			</PngExportable>
			<div className="relative">
				<Watermark />
				<TableExportable
					title={t(`report_title.ppe_table`, { start, end })}
					find={{ start, end }}
					url="/report/ppe/export"
					data={table}
					columns={[
						{
							label: t("fields.unit"),
							render: { type: "string", field: "user.unit.name" },
						},
						{
							label: t("ppe.user"),
							render: ({ user }) =>
								`${user.firstname} ${user.lastname} (${user.position})`,
						},
						{
							label: t("ppe-item.name"),
							render: { type: "string", field: "item.name" },
						},
						{
							label: t("ppe.quantity"),
							render: { type: "string", field: "quantity" },
						},
						{
							label: t("ppe.total"),
							render: ({ total }) => numberWithCommas(total),
						},
						{
							label: t("ppe.status"),
							render: {
								type: "trans",
								tPath: "const.ppe_status",
								field: "status",
							},
						},
						{
							label: t("fields.createdby"),
							render: ({ createdby }) =>
								`${createdby.firstname} ${createdby.lastname} (${createdby.position})`,
						},
						{
							label: t("fields.created"),
							render: { type: "date", field: "created" },
						},
					]}
				/>
			</div>
		</div>
	);
};

export default PpeReport;
