import { useTranslation } from "react-i18next";
import Table from "../list/Table";
import {
	formatDate,
	formatMedia,
	formatLink,
	formatString,
	translateCell,
} from "../utils/format";
import { useAuth } from "../utils/auth";

const UserTable = ({ org }) => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { role } = user;
	const org_id = org || user.org;

	return (
		<>
			<h1 className="bordered">{t("title.active-users")}</h1>
			<Table
				url={`/user/table/${org_id}`}
				exportable={"Active user list"}
				defaultFind={{ active: true }}
				columns={[
					{
						nex: true,
						key: "profile",
						label: "user.profile",
						render: (row) => formatMedia(row, "profile", "name"),
					},
					{
						ex: "phone",
						key: "phone",
						label: "user.phone",
						sortable: true,
						filter: true,
						render:
							role === "admin"
								? (row) => formatLink(row, "phone", `${row._id}`)
								: null,
					},
					{
						key: "role",
						label: "user.role",
						sortable: true,
						filter: {
							type: "select",
							axio: "/const/static/role",
						},
						render: (row) => translateCell(row, t, "role", "role"),
					},
					{
						key: "lastname",
						label: "user.lastname",
						sortable: true,
						filter: true,
					},
					{
						key: "firstname",
						label: "user.firstname",
						sortable: true,
						filter: true,
					},
					{
						key: "position",
						label: "user.position",
						sortable: true,
						filter: true,
					},
					{
						key: "unit",
						label: "user.unit",
						sortable: true,
						filter: {
							type: "select",
							axio: `/const/select/unit/${org_id}`,
						},
						render: (row) => formatString(row, "unit.name"),
					},
					{
						key: "point",
						label: "user.point",
						sortable: true,
						filter: { type: "number" },
					},
					{
						key: "rank",
						label: "user.rank",
						sortable: true,
						filter: { type: "number" },
					},
					{
						key: "email",
						label: "user.email",
						sortable: true,
						filter: true,
					},
					{
						key: "created",
						label: "fields.created",
						sortable: true,
						filter: { type: "date" },
						render: (row) => formatDate(row, "created"),
					},
				]}
			/>
			<h1 className="bordered">{t("title.inactive-users")}</h1>
			<Table
				url={`/user/table/${org_id}`}
				defaultFind={{ active: false }}
				columns={[
					{
						key: "profile",
						label: "user.profile",
						render: (row) => formatMedia(row, "profile", "name"),
					},
					{
						key: "phone",
						label: "user.phone",
						sortable: true,
						filter: true,
						render:
							role === "admin"
								? (row) => formatLink(row, "phone", `${row._id}`)
								: null,
					},
					{
						key: "role",
						label: "user.role",
						sortable: true,
						filter: {
							type: "select",
							axio: "/const/static/role",
						},
						render: (row) => translateCell(row, t, "role", "role"),
					},
					{
						key: "lastname",
						label: "user.lastname",
						sortable: true,
						filter: true,
					},
					{
						key: "firstname",
						label: "user.firstname",
						sortable: true,
						filter: true,
					},
					{
						key: "position",
						label: "user.position",
						sortable: true,
						filter: true,
					},
					{
						key: "unit",
						label: "user.unit",
						sortable: true,
						filter: {
							type: "select",
							axio: `/const/select/unit/${org_id}`,
						},
						render: (row) => formatString(row, "unit.name"),
					},
					{
						key: "point",
						label: "user.point",
						sortable: true,
						filter: { type: "number" },
					},
					{
						key: "rank",
						label: "user.rank",
						sortable: true,
						filter: { type: "number" },
					},
					{
						key: "email",
						label: "user.email",
						sortable: true,
						filter: true,
					},
					{
						key: "created",
						label: "fields.created",
						sortable: true,
						filter: { type: "date" },
						render: (row) => formatDate(row, "created"),
					},
				]}
			/>
		</>
	);
};

export default UserTable;
