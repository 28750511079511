import { numberWithCommas } from "../defines";
import { CreatedBy } from "../template";

const TopCreatedBy = ({ data = [], renderRight }) => {
	return (
		<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
			{data.map((row, index) => {
				const { firstname, lastname, position, profile, total } = row;

				return (
					<div
						key={index}
						className="flex justify-between p-5 bg-white shadow-md"
					>
						<div className="flex">
							<div className="flex-shrink-0 w-8">{index + 1}.</div>
							<CreatedBy
								key={index}
								user={{ firstname, lastname, position, profile }}
							/>
						</div>
						<div className="flex-shrink-0 text-2xl font-bold text-right">
							{renderRight ? renderRight(row) : numberWithCommas(total)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default TopCreatedBy;
