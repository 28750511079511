import { useTranslation } from "react-i18next";
import { useState } from "react";
import { RiRepeat2Fill } from "react-icons/ri";
import { DatePicker } from "../inputs";
import { Loader, Maintain } from "../template";
import { mainApi } from "../utils/api";
import { translateError } from "../utils/alert";
import { DEBUG } from "../defines";
import { useEffect } from "react";
import {
	differenceInMonths,
	endOfMonth,
	startOfMonth,
	addMonths,
	format,
} from "date-fns";
import Hazard from "./pages/Hazard";
import Training from "./pages/Training";
import Checklist from "./pages/Checklist";
import Induction from "./pages/Induction";
import Violation from "./pages/Violation";
import Alert from "./pages/Alert";
import Step from "./pages/Step";
import Permission from "./pages/Permission";
import Ppe from "./pages/Ppe";
import Incident from "./pages/Incident";

const RESULT = {
	hazard: (props) => <Hazard {...props} />,
	training: (props) => <Training {...props} />,
	checklist: (props) => <Checklist {...props} />,
	induction: (props) => <Induction {...props} />,
	violation: (props) => <Violation {...props} />,
	alert: (props) => <Alert {...props} />,
	step: (props) => <Step {...props} />,
	permission: (props) => <Permission {...props} />,
	ppe: (props) => <Ppe {...props} />,
	incident: (props) => <Incident {...props} />,
};

const Panel = ({ type }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [start, setStart] = useState(
		addMonths(
			startOfMonth(DEBUG ? new Date("2023-02-01") : new Date()),
			DEBUG ? -12 : -5
		)
	);
	const [end, setEnd] = useState(
		endOfMonth(DEBUG ? new Date("2023-02-01") : new Date())
	);

	const [data, setData] = useState();

	const fetchData = async () => {
		try {
			setLoading(true);
			if (!start || !end) {
				throw new Error("report.start_end");
			}
			if (start > end) {
				throw new Error("report.invalid_dates");
			}
			let start_date = start,
				end_date = end;

			try {
				start_date = start.toDate();
				end_date = end.toDate();
			} catch (error) {}

			if (differenceInMonths(end_date, start_date) > 12) {
				throw new Error("report.year_limit");
			}

			const response = await mainApi({
				url: `/report/${type}`,
				method: "POST",
				data: {
					start: start_date,
					end: end_date,
				},
			});

			response && setData(response.data);
		} catch (error) {
			translateError(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		setData(null);
	}, [start, end]);

	if (!RESULT[type]) return <Maintain />;

	return (
		<div className="relative">
			{loading && <Loader />}
			<div className="flex gap-2 mb-4">
				<DatePicker
					placeholder={t("fields.start")}
					value={start}
					onChange={setStart}
				/>
				<DatePicker
					placeholder={t("fields.end")}
					value={end}
					onChange={setEnd}
				/>
				<button
					className="primary"
					onClick={() => {
						fetchData();
					}}
				>
					<RiRepeat2Fill className="text-xl mr-2" /> {t("button.run")}
				</button>
			</div>
			{data && (
				<div>
					{RESULT[type]({
						...data,
						start: start && format(new Date(start), "yyyy-MM-dd"),
						end: end && format(new Date(end), "yyyy-MM-dd"),
					})}
				</div>
			)}
		</div>
	);
};

export default Panel;
