import { useTranslation } from "react-i18next";
import { HiQuestionMarkCircle } from "react-icons/hi";

const getEmbedUrl = (url) => {
	const videoId = url.split("v=")[1]?.split("&")[0];
	return videoId ? `https://www.youtube.com/embed/${videoId}` : "";
};

const URL = {
	hazard:
		"https://www.youtube.com/watch?v=WCXZil5PEPQ&list=PLJ7LMUfaZcue6wwpnj_Jd-hg76f5J1lRG",
	alert:
		"https://www.youtube.com/watch?v=b-R12AYKGF0&list=PLJ7LMUfaZcue6wwpnj_Jd-hg76f5J1lRG&index=2",
};

const IntroPanel = ({ type }) => {
	const { t } = useTranslation();

	if (!URL[type])
		return (
			<div className="text-4xl p-4 text-center flex flex-col items-center opacity-10">
				<div className="mb-4">{t("message.no-intro")}</div>
				<HiQuestionMarkCircle size={250} />
			</div>
		);

	return (
		<div>
			<iframe
				width="100%"
				height="600"
				src={getEmbedUrl(URL[type])}
				title="YouTube"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			></iframe>
		</div>
	);
};

export default IntroPanel;
